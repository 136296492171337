import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//  Ui-kit
import Button from 'ui-kit/button/button';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import { CartShipping } from 'components/cart/cart-shipping.component';
import EmptyCart from 'components/cart/empty-cart/empty-cart';
import { CartShippingProps } from 'components/cart/types';
import HealthConditions from 'components/easy-refill/health-conditions/health-conditions.component';
import { CartTotal } from 'components/easy-refill/review/cart-total.component';
import { PrescriptionInformation } from 'components/easy-refill/review/prescription-information.component';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PaymentMethods, { PaymentMethodsProps } from 'components/payment-methods/payment-methods.components';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

// Pages
import UpdateCartModalContent, {
    FailureUpdateCartModalContent
} from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { getEasyRefillMainCart } from 'state/easy-refill/easy-refill.helpers';
import {
    easyRefillCancelOrderLineRoutine,
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillSubmitOrderRoutine,
    easyRefillUpdatePaymentRoutine,
    easyRefillUpdateShippingMethodRoutine,
    easyRefillUpdateShippingRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    cartSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillAddressesSelector,
    easyRefillCartItemsSelector,
    easyRefillcartSubtotalSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillExpeditedShippingSelector,
    easyRefillIsCaregiverSelector,
    easyRefillOrderBillShipMethodSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlansSelector,
    easyRefillRxsToOrderSelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';

// Types
import { EasyRefillCaregiverCartData, ExtendedEasyRefillCartObjectPayload, PaymentCard } from 'types/easy-refill';

// Utils
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';
import { processEasyRefillCart } from 'util/easy-refill';

// Hooks
import { useEasyRefill } from 'hooks/useEasyRefill';
import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';
import useWindowDimensions from 'hooks/useWindowDimensions';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '..';
// Styles
import './review.style.scss';
import { getPhoneNumber } from 'util/globalVariables';

// Helper function to check if user have an active payment card.
const hasPaymentData = (patientPaymentCards: PaymentCard[]): boolean => {
    const today = moment(new Date()).format('MM/DD/YYYY');

    const paymentData = patientPaymentCards.filter((card) => {
        const isCardExpired = moment(`${card.cardMonthNum}/01/${card.cardYear}`).isBefore(today, 'day');
        return card.cardActive && !isCardExpired;
    });

    return paymentData.length > 0;
};

const EasyRefillReview = ({ data }: { data: GatsbyTypes.EasyRefillReviewDataQuery }) => {
    // hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { showBirdiPricing } = useEasyRefill();
    const { existingAllergies, existingConditions, userHasNotSubmittedAllergies, userHasNotSubmittedConditions } =
        useEasyRefillHealthConditions();

    // Selectors
    const accountHasInsurance = useSelector(easyRefillAccountHasInsuranceSelector);
    const expeditedShipping = useSelector(easyRefillExpeditedShippingSelector);
    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);
    const patientAddresses = useSelector(easyRefillAddressesSelector);
    const patientPaymentCards = useSelector(easyRefillPaymentCardsSelector);
    const patientSelectedPaymentMethod = useSelector(easyRefillOrderPaymentCardSelector);
    const prescriptionsInCart = useSelector(easyRefillCartItemsSelector);
    const shipMethodId = useSelector(easyRefillOrderBillShipMethodSelector);
    const epostPatientNum: string = useSelector(easyRefillEpostPatientNumSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const cartsObject = useSelector(cartSelector);
    const isCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const cartObject = getEasyRefillMainCart(cartsObject, epostPatientNum);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const cartSubtotal = useSelector(easyRefillcartSubtotalSelector);
    let orderBillShip = null;

    // Statuses
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isRemoveBusy, setIsRemoveBusy] = useState<boolean>(false);
    const [cardAddresses, setCardAddresses] = useState<AddressCardProps[]>([]);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedEasyRefillCartObjectPayload[] | undefined>(
        undefined
    );

    // General
    const { blueSkyBackground } = data;
    let hasAdjudicatedRxs = false;

    useEffect(() => {
        dispatch(easyRefillGetCartRoutine.trigger());
    }, []);

    useEffect(() => {
        hasAdjudicatedRxs = (prescriptionsInCart || []).length > 0;
        orderBillShip = cartsObject?.find((cart) => cart?.order.orderBillShip.patientBillAddressSeq !== null);
    }, [cartObject, hasAdjudicatedRxs]);

    // useEffect section
    useEffect(() => {
        if (showBirdiPricing) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: easyRefillRxsToOrder,
                    forceBirdiInsurance: ALLOW_INSURED_BIRDI_PRICE
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showBirdiPricing]);

    useEffect(() => {
        const addressCard: AddressCardProps[] = patientAddresses.map((address) => {
            const selectedAddress = cartsObject?.find((cart) =>
                !isCaregiver && cart?.order.orderBillShip.dependentAddressSeqNum
                    ? cart?.order.orderBillShip.dependentAddressSeqNum === address.addressSeqNum
                    : cart?.order.orderBillShip.patientBillAddressSeq === address.addressSeqNum
            );

            return {
                addressSeqNum: address.addressSeqNum,
                defaultAddress: address.defaultShip,
                isChecked: selectedAddress,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                country: address.country,
                state: address.state,
                zipcode: address.zipcode,
                zipcodeFour: address.zipcodeFour,
                defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
                addressType: address.addressTypeDesc,
                isProfile: false
            };
        });

        setCardAddresses(addressCard);
    }, [patientAddresses, cartObject, t]);

    // Fetch health conditions
    useEffect(() => {
        dispatch(easyRefillFetchHealthConditionsRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cartsObject) {
            const extendedCart = processEasyRefillCart(
                cartsObject,
                accountHasInsurance,
                prescriptionsInCart,
                drugDiscountPrices,
                accountPlans
            );

            setExtendedCartObject(extendedCart);
        }
    }, [accountHasInsurance, accountPlans, cartsObject, drugDiscountPrices, prescriptionsInCart]);

    // handle functions
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillOrderFailed'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmitOrderClick = () => {
        setIsSubmitting(true);
        dispatch(
            easyRefillSubmitOrderRoutine.trigger({
                currentShippingPrice: expeditedShipping ? EXPEDITED_SHIPPING_COST : 0,
                lineItems: prescriptionsInCart,
                onSuccess: () => {
                    setIsSubmitting(false);
                    navigate('/easy-refill/order-confirmation');
                },
                onFailure: () => {
                    setIsSubmitting(false);
                    handleShowErrorModal(
                        t('pages.easyRefill.review.errors.orderError', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })
                    );
                }
            })
        );
    };

    const handleGoBack = useCallback(() => {
        navigate('/easy-refill/prescriptions');
    }, []);

    const handleRemoveRx = useCallback(
        (prescriptionToRemove: EasyRefillCaregiverCartData) => {
            setIsRemoveBusy(true);
            if (!prescriptionToRemove) {
                return;
            }

            const epostNumPatient = prescriptionToRemove.prescriptionDetail.epostPatientNum;
            const rxNumber = prescriptionToRemove.rxNumber;
            const lineId = prescriptionToRemove.epostRxScriptId;
            const orderNum = prescriptionToRemove.prescriptionDetail.epostOrderNum;

            dispatch(
                easyRefillCancelOrderLineRoutine.trigger({
                    rxNumber,
                    lineId,
                    orderNum,
                    epostNumPatient,
                    onSuccess: () => {
                        dispatch(easyRefillGetCartRoutine.trigger());
                        setIsRemoveBusy(false);
                    },
                    onFailure: () => {
                        setIsRemoveBusy(false);
                        handlePrescriptionsError();
                    }
                })
            );
        },
        [dispatch]
    );

    const handlePrescriptionsError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleDefaultShippingClick = useCallback<NonNullable<CartShippingProps['handleDefaultShippingClick']>>(() => {
        dispatch(
            easyRefillUpdateShippingMethodRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(easyRefillGetCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    const handleExpeditedShippingClick = useCallback<
        NonNullable<CartShippingProps['handleDefaultShippingClick']>
    >(() => {
        dispatch(
            easyRefillUpdateShippingMethodRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(easyRefillGetCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    const handlePaymentMethodSelectionChange = useCallback<NonNullable<PaymentMethodsProps['onCardSelectionChange']>>(
        (newSelectedCard) => {
            if (!newSelectedCard) {
                return;
            }
            dispatch(
                easyRefillUpdatePaymentRoutine.trigger({
                    paymentCardSeqNum: newSelectedCard?.cardSeqNum,
                    onSuccess: () => {
                        dispatch(
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <BirdiModalContent
                                            icon={'success'}
                                            title={t('modals.paymentMethods.success.title')}
                                            body={t('modals.paymentMethods.success.description')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.paymentMethods.success.cta'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ReviewOrderUpdateCartEasyRefill'
                                        }
                                    ]
                                })
                            )
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            dispatch(
                                openModal({
                                    showClose: true,
                                    className: 'prescription-modal',
                                    bodyContent: <UpdateCartModalContent area={'ERROR'} />,
                                    ctas: [
                                        {
                                            label: t('modals.updateCart.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ReviewOrderUpdateCart'
                                        }
                                    ]
                                })
                            )
                        );
                    }
                })
            );
        },
        [dispatch, t]
    );

    const handleShippingAddressChange = (address: AddressCardProps) => {
        const selectedAddress = patientAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        dispatch(
            easyRefillUpdateShippingRoutine.trigger({
                addressSeqNum: selectedAddress?.addressSeqNum || address.addressSeqNum,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                    dispatch(
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCart'
                                    }
                                ]
                            })
                        )
                    );
                },
                onFailure: () => {
                    dispatch(
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: <UpdateCartModalContent area={'ERROR'} />,
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCart'
                                    }
                                ]
                            })
                        )
                    );
                }
            })
        );
    };

    const onDefaultCardChanged = () => {
        dispatch(easyRefillGetCartRoutine.trigger());
    };

    useEffect(() => {
        if (!easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <Spinner isVisible={isRemoveBusy} t={t} />
            {(prescriptionsInCart || []).length > 0 ? (
                <PageSection>
                    <div className="easy-refill-cart">
                        <>
                            <InternalHeader
                                title={t('pages.easyRefill.review.headlineText')}
                                eyebrowText={t('pages.easyRefill.review.eyebrowText')}
                                sectionIndex={0}
                                columnWidthOnDesktop={10}
                                variant="blue-background"
                                showSpacer
                            />

                            <div className="easy-refill-cart-content">
                                <div>
                                    {/* SHIPPING METHOD */}
                                    <CartReviewSectionHeader
                                        label={t('pages.easyRefill.review.sectionHeaders.shippingMethod')}
                                    />
                                    <CartShipping
                                        handleDefaultShippingClick={handleDefaultShippingClick}
                                        handleExpeditedShippingClick={handleExpeditedShippingClick}
                                        shipMethodId={shipMethodId}
                                        t={t}
                                    />

                                    {/* PRESCRIPTION INFORMATION */}
                                    <CartReviewSectionHeader
                                        label={t('pages.easyRefill.review.sectionHeaders.prescriptionInformation')}
                                        pricingLabel={t('pages.cart.pricing')}
                                    />
                                    {/* @TODO: We tried to reuse this component to avoid duplicating components with the same function.
                                    But in the future, if it's necessary, create a separate component to this area. */}
                                    <PrescriptionInformation
                                        t={t}
                                        fullCart={extendedCartObject || []}
                                        prescriptions={prescriptionsInCart}
                                        isCaregiver={easyRefillIsCaregiver}
                                        accountHasInsurance={accountHasInsurance}
                                        onRemoveOrder={handleRemoveRx}
                                    />

                                    {/* Add more prescriptions CTA */}
                                    <div className="easy-refill-review-add-more container">
                                        <Button
                                            type="button"
                                            variant="text-blue"
                                            label={t('pages.easyRefill.review.buttons.addMore')}
                                            onClick={handleGoBack}
                                            chevron="right"
                                        />
                                    </div>

                                    {/* CART TOTAL */}
                                    <CartTotal
                                        t={t}
                                        hasUnknownPrice={true}
                                        initialOrderPrice={`${cartSubtotal}`}
                                        currentShippingPrice={
                                            shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0
                                        }
                                        accountHasInsurance={accountHasInsurance}
                                    />

                                    {/* SHIPPING INFORMATION */}
                                    <CartReviewSectionHeader
                                        label={t('pages.easyRefill.review.sectionHeaders.shippingInformation')}
                                    />
                                    <ShippingAddresses
                                        addressData={cardAddresses}
                                        addNewAddressButtonLabel={t('pages.easyRefill.review.buttons.addAddress')}
                                        buttonVariant="text-blue"
                                        customContainerClasses="mt-2"
                                        onSetAsShipping={handleShippingAddressChange}
                                        isProfile={false}
                                        isEasyRefill={true}
                                        showLabels={true}
                                    />

                                    {/* PAYMENT INFORMATION */}
                                    <CartReviewSectionHeader
                                        label={t('pages.easyRefill.review.sectionHeaders.paymentInformation')}
                                    />
                                    <PaymentMethods
                                        paymentData={patientPaymentCards}
                                        addNewPaymentButtonLabel={t('pages.easyRefill.review.buttons.addPayment')}
                                        removeCardLinkClassName="text-primary"
                                        setAsDefaultCardLinkClassName="text-primary"
                                        buttonVariant="text-blue"
                                        customContainerClasses="mt-2"
                                        isProfile={false}
                                        isEasyRefill={true}
                                        onCardSelectionChange={handlePaymentMethodSelectionChange}
                                        selectedCardSeqNum={patientSelectedPaymentMethod?.cardSeqNum}
                                        showLabel
                                        showSetDefaultLink
                                        showSelectCardRadioInput
                                        showRemoveCardLink={false}
                                        onDefaultCardChanged={onDefaultCardChanged}
                                    />

                                    {/* HEALTH PROFILE */}
                                    <CartReviewSectionHeader
                                        label={t('pages.easyRefill.review.sectionHeaders.healthProfile')}
                                    />
                                    <HealthConditions
                                        isUserHasNotSubmittedConditions={userHasNotSubmittedConditions}
                                        existingConditions={existingConditions}
                                        existingAllergies={existingAllergies}
                                        isUserHasNotSubmittedAllergies={userHasNotSubmittedAllergies}
                                    />

                                    {/* @TODO: We added this div with spacing because we don't have the Health Profile implemented yet.
                                    Remove this div in the future if is necessary and implement just the ToastBox component. */}
                                    <div className="mt-4">
                                        <ToastBox variant="info">{t('pages.easyRefill.review.disclaimer')}</ToastBox>
                                    </div>

                                    <div className="d-flex flex-column flex-lg-row justify-content-lg-end align-items-center mt-4 easy-refill-cart-ctas">
                                        <Button
                                            onClick={handleGoBack}
                                            variant="text-blue"
                                            type="button"
                                            label={t('pages.easyRefill.review.buttons.backToPrescriptions')}
                                            className="back-to-prescriptions-btn text-cerulean no-min-width"
                                        />
                                        <Button
                                            async
                                            onClick={handleSubmitOrderClick}
                                            variant="primary"
                                            type="submit"
                                            label={t('pages.easyRefill.review.buttons.submit')}
                                            isBusy={isSubmitting}
                                            disabled={
                                                orderBillShip?.order.orderBillShip?.paymentCardSeqNum === null ||
                                                orderBillShip?.order.orderBillShip?.patientBillAddressSeq === null ||
                                                orderBillShip?.order.orderBillShip?.patientShipAddressSeq === null ||
                                                !hasPaymentData(patientPaymentCards) ||
                                                patientAddresses.length < 1 ||
                                                userHasNotSubmittedConditions ||
                                                userHasNotSubmittedAllergies ||
                                                isSubmitting
                                            }
                                            className={`btn-bold ${width < 700 ? 'w-100' : ''}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </PageSection>
            ) : (
                <EmptyCart
                    cartColor="black"
                    className="internal-header"
                    title={t('pages.easyRefill.review.empty.emptyTitle')}
                    body={t('pages.easyRefill.review.empty.emptyBody')}
                    btnLabel={t('pages.easyRefill.review.empty.emptyButton')}
                    onClickButton={handleGoBack}
                />
            )}
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillReview, 'easy-refill');

export const query = graphql`
    query EasyRefillReviewData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
